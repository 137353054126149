.notes-row {
  padding: 0 25px;
  display: flex;
  flex-direction: row;
  gap: 15px;

  .icons {
    align-self: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    img {
      margin-right: 7px;
    }
  }

  div {
    flex: 1 1 15%;
  }
}

.divider {
  border-top: gray;
  width: 100%;
  margin: 15px auto;
}

.hidden {
  display: none;
}

.textarea {
  margin: 20px 145px 40px 25px;
}

.new-elements {
  margin-top: 15px;
}

.notes-row-container {
  margin-bottom: 20px;
}
