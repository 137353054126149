.action-area {
  min-height: auto;
}

p {
  padding-left: 10px;
  margin-bottom: 0;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

hide-hr hr {
  display: none;
}

.pressetext-dialog-form {
  display: flex;
  gap: 5px;
}
