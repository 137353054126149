.login-field {
  display: flex;
  margin-left: auto;
  max-width: 520px;
  gap: 10px;

  align-items: center;
}

.subline {
  margin-left: auto;
  max-width: 520px;
  margin-top: 10px;
  a,
  a:visited {
    text-decoration: none;
    font-size: 0.8em;
    color: rgb(56, 92, 142);
  }
}

.loggedin {
  position: relative;
  top: 10px;
  display: flex;
  align-items: center;

  tp-text-button {
    margin: 0 10px;
  }
}
