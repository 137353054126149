.login-field {
  align-items: center;
  gap: 10px;
  max-width: 520px;
  margin-left: auto;
  display: flex;
}

.subline {
  max-width: 520px;
  margin-top: 10px;
  margin-left: auto;
}

.subline a, .subline a:visited {
  color: #385c8e;
  font-size: .8em;
  text-decoration: none;
}

.loggedin {
  align-items: center;
  display: flex;
  position: relative;
  top: 10px;
}

.loggedin tp-text-button {
  margin: 0 10px;
}

.notes-row {
  flex-direction: row;
  gap: 15px;
  padding: 0 25px;
  display: flex;
}

.notes-row .icons {
  cursor: pointer;
  justify-content: space-between;
  align-self: center;
  display: flex;
}

.notes-row .icons img {
  margin-right: 7px;
}

.notes-row div {
  flex: 15%;
}

.textarea {
  margin: 20px 145px 40px 25px;
}

.new-elements {
  margin-top: 15px;
}

.notes-row-container {
  margin-bottom: 20px;
}

.saved-searches-row {
  flex-direction: row;
  gap: 15px;
  margin: 0 0 20px;
  padding-left: 10px;
  display: flex;
}

.saved-searches-row .datepicker {
  flex: 12%;
}

.saved-searches-row .icons {
  cursor: pointer;
  align-self: center;
  min-width: 117px;
}

.saved-searches-row .icons img {
  margin-right: 7px;
}

.saved-searches-row .actions {
  align-self: center;
  display: flex;
}

.divider {
  border-top: 1px solid #b1b1b1;
  width: 100%;
  margin: 15px auto;
}

.content {
  color: #fff;
  margin-bottom: 50px;
}

.pressetexte-row {
  flex-flow: column wrap;
  gap: 15px;
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.pressetexte-row .pressetexte-row-overview {
  flex-direction: row;
  gap: 15px;
  width: 100%;
  display: flex;
}

.pressetexte-row .pressetexte-row-overview tp-text-field[id="name"] {
  width: 200%;
}

.pressetexte-row .icons {
  cursor: pointer;
  justify-content: space-between;
  align-self: center;
  display: flex;
}

.pressetexte-row .icons img {
  margin-right: 7px;
}

.pressetexte-row div {
  flex: 15%;
}

.hidden {
  display: none;
}

.detail-area {
  width: 100%;
  margin: 5px 120px 20px 0;
}

.detail-area tp-button, .detail-area tp-text-field {
  margin-top: 15px;
}

.title {
  flex-direction: row;
  gap: 15px;
  display: flex;
}

.topic-detail {
  opacity: .5;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 10px;
  padding: 3px;
  display: flex;
}

.topic-detail .overflow-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 97%;
  display: inline-block;
  overflow: hidden;
}

.topic-detail .left, .topic-detail .right {
  width: 50%;
}

.pressetexte-form-column-wrapper {
  gap: 15px;
  display: flex;
}

.pressetexte-form-column {
  flex-direction: column;
  flex: auto;
  display: flex;
}

.pressetexte-form-generator-wrapper {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.button-row {
  display: flex;
}

.action-area {
  min-height: auto;
}

p {
  margin-bottom: 0;
  padding-left: 10px;
}

.actions {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

hide-hr hr {
  display: none;
}

.pressetext-dialog-form {
  gap: 5px;
  display: flex;
}

.content {
  color: #fff;
  margin-bottom: 25px;
  padding-top: 1px;
}

.headline {
  letter-spacing: .005em;
  color: #fff;
  border-bottom: 3px solid #fff;
  margin-bottom: 10px;
  padding-bottom: 7px;
  font-size: 20px;
  font-weight: 500;
}

.content-header-block {
  letter-spacing: .02em;
  background: #d9e1ec;
  border: 1px solid #d3d4d4;
  border-top-color: #0000;
  border-bottom: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-top: 0;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.help-content {
  color: #fff;
  letter-spacing: .02em;
  margin-top: -5px;
  margin-bottom: 50px;
  padding: 15px 25px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.help-content p {
  padding: 0;
}

.help-content .tip-row {
  gap: 5%;
  display: flex;
}
/*# sourceMappingURL=index.f16c7fb9.css.map */
