.pressetexte-row {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 15px;
  display: flex;
  flex-flow: column wrap;
  gap: 15px;

  .pressetexte-row-overview {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;

    tp-text-field[id="name"] {
      width: 200%;
    }
  }

  .icons {
    align-self: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    img {
      margin-right: 7px;
    }
  }

  div {
    flex: 1 1 15%;
  }
}

.hidden {
  display: none;
}

.detail-area {
  margin: 5px 120px 20px 0;
  width: 100%;

  tp-button,
  tp-text-field {
    margin-top: 15px;
  }
}

.title {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.topic-detail {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 3px;
  opacity: 50%;
  margin-bottom: 10px;

  .overflow-ellipsis {
    width: 97%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .left {
    width: 50%;
  }
  .right {
    width: 50%;
  }
}

.pressetexte-form-column-wrapper {
  display: flex;
  gap: 15px;
}

.pressetexte-form-column {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
}

.pressetexte-form-generator-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.button-row {
  display: flex;
}
