.saved-searches-row {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 0 0;

  .datepicker {
    flex: 1 1 12%;
  }
  .icons {
    align-self: center;
    cursor: pointer;
    min-width: 117px;

    img {
      margin-right: 7px;
    }
  }

  .actions {
    display: flex;
    align-self: center;
  }
  margin-bottom: 20px;
}

.divider {
  border-top: 1px solid rgb(177, 177, 177);
  width: 100%;
  margin: 15px auto;
}
