@import "../../css/colors.scss";

@mixin fonts {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.02em;
  line-height: 20px;
}

.headline {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em;
  padding-bottom: 7px;
  color: white;
  border-bottom: 3px solid white;
  margin-bottom: 10px;
}

.content-header-block {
  background: $blockBackground;
  border: 1px solid $blockBorderColor;
  border-top: 1px solid transparent;
  border-bottom: 0;
  margin-top: 0;
  padding: 5px 15px;

  border-top-left-radius: 15px 15px;
  border-top-right-radius: 15px 15px;

  @include fonts;
}

.help-content {
  color: white;
  margin-bottom: 50px;
  margin-top: -5px;
  padding: 15px 25px;

  p {
    padding: 0;
  }

  .tip-row {
    display: flex;
    gap: 5%;
  }

  @include fonts;
}
